<template>
    <dv-border-box-8>
        <el-row>
            <el-col :span="3">
                    <div class="parent" >
                        <dv-decoration-11 style="width:200px;height:60px;margin-top:70px;margin-left:20px;">{{ name }}</dv-decoration-11>
                    </div>
                </el-col>
                <el-col :span="4">
                    <div class ="charts-chart" ref = "speedCharts" />
                </el-col>
                <el-col :span="4">
                    <div class ="charts-chart" ref = "torsionCharts" />
                </el-col>
                <el-col :span="4">
                    <div class ="charts-chart" ref = "posCharts" />
                </el-col>
                <el-col :span="4">
                    <div class ="charts-chart" ref = "wireCharts" />
                </el-col>
                <el-col :span="4">
                    <div class ="charts-chart" ref = "tempCharts" />
            </el-col>
        </el-row>
    </dv-border-box-8>
</template>
<script>
import * as echarts from 'echarts';
let speedChart = null;
let tempChart = null;
let torsionChart = null;
let wireChart = null;
let posChart = null;
export default ({
    props: {
      name: String,
      infos: Object
    },
    data(){
       return{
            count:0,
            speedOption:{
                series: [
                {
                    type: 'gauge',
                    min: 0,
                    max: 1000,
                    itemStyle: {
                        color: '#58D9F9',
                        shadowColor: 'rgba(0,138,255,0.45)',
                        shadowBlur: 10,
                        shadowOffsetX: 2,
                        shadowOffsetY: 2
                    },
                    progress: {
                        show: true,
                        width: 6
                    },
                    axisLine: {
                        lineStyle: {
                        width: 6
                        }
                    },
                    axisLabel: {
                        distance: 10,
                        color: '#999',
                        fontSize: 8
                    },
                    title: {
                        show: true
                    },
                    detail: {
                        valueAnimation: true,
                        fontSize: 20,
                        offsetCenter: [0, '80%'],
                        color: 'inherit'
                    },
                    // data: [
                    //     {
                    //         value: 30,
                    //         name:"转速"
                    //     }
                    // ]
                      data:this.infos.speed
                    }
                ]
            },
            //扭矩
            torsionOptions:{
                series: [
                {
                    type: 'gauge',
                    min: 0,
                    max: 100,
                    itemStyle: {
                        color: '#00EE00',
                        shadowColor: 'rgba(0,138,255,0.45)',
                        shadowBlur: 10,
                        shadowOffsetX: 2,
                        shadowOffsetY: 2
                    },
                    progress: {
                        show: true,
                        width: 6
                    },
                    axisLine: {
                        lineStyle: {
                        width: 6
                        }
                    },
                    axisLabel: {
                        distance: 10,
                        color: '#999',
                        fontSize: 8
                    },
                    title: {
                        show: true
                    },
                    detail: {
                        valueAnimation: true,
                        fontSize: 20,
                        offsetCenter: [0, '80%'],
                        color: 'inherit'
                    },
                        // data: [
                        //     {
                        //     value: 30,
                        //     name:"扭矩"
                        //     }
                        // ]
                         data:this.infos.torsion
                    }
                ]
            },
            posOptions:{
                series: [
                {
                    type: 'gauge',
                    min: 0,
                    max: 100,
                    itemStyle: {
                        color: '#6E8B3D',
                        shadowColor: 'rgba(0,138,255,0.45)',
                        shadowBlur: 10,
                        shadowOffsetX: 2,
                        shadowOffsetY: 2
                    },
                    progress: {
                        show: true,
                        width: 6
                    },
                    axisLine: {
                        lineStyle: {
                        width: 6
                        }
                    },
                    axisLabel: {
                        distance: 10,
                        color: '#999',
                        fontSize: 8
                    },
                    title: {
                        show: true
                    },
                    detail: {
                        valueAnimation: true,
                        fontSize: 20,
                        offsetCenter: [0, '80%'],
                        color: 'inherit'
                    },
                        // data: [
                        //     {
                        //     value: 30,
                        //     name:"电机位置"
                        //     }
                        // ]
                         data:this.infos.position
                    }
                ]
            },
            tempOptions: {
                series: [
                {
                    type: 'gauge',
                    center: ['50%', '60%'],
                    startAngle: 200,
                    endAngle: -20,
                    min: -10,
                    max: 80,
                    splitNumber: 10,
                    itemStyle: {
                        color: '#8B658B',
                        shadowColor: 'rgba(0,138,255,0.45)',
                        shadowBlur: 10,
                        shadowOffsetX: 2,
                        shadowOffsetY: 2
                    },
                    pointer: {
                        show: false
                    },
                    axisLine: {
                        lineStyle: {
                        width: 10
                        }
                    },
                    axisTick: {
                        distance: -25,
                        splitNumber: 5,
                        lineStyle: {
                        width: 2,
                        color: '#999'
                        }
                    },
                    progress: {
                        show: true,
                        width: 8
                    },
                    splitLine: {
                        distance: -4,
                        length: 4,
                        lineStyle: {
                        width: 1,
                        color: '#999'
                        }
                    },
                    axisLabel: {
                        distance: -30,
                        color: '#999',
                        fontSize: 10
                    },
                    anchor: {
                        show: false
                    },
                    title: {
                        show: true
                    },
                    detail: {
                        valueAnimation: true,
                        width: '60%',
                        lineHeight: 40,
                        borderRadius: 8,
                        offsetCenter: [0, '-15%'],
                        fontSize: 20,
                        fontWeight: 'bolder',
                        formatter: '{value} °C',
                        color: 'inherit'
                    },
                    // data: [
                    //     {
                    //     value: 50.23,
                    //     name:"电机温度"
                    //     }
                    // ]
                    data:this.infos.motorTemp
                }]
            },
            wireOptions:{
                series: [
                {
                    type: 'gauge',
                    center: ['50%', '60%'],
                    startAngle: 200,
                    endAngle: -20,
                    min: -10,
                    max: 80,
                    splitNumber: 10,
                    itemStyle: {
                        color: '#FFAB91'
                    },
                    pointer: {
                        show: false
                    },
                    axisLine: {
                        lineStyle: {
                        width: 10
                        }
                    },
                    axisTick: {
                        distance: -25,
                        splitNumber: 5,
                        lineStyle: {
                        width: 2,
                        color: '#999'
                        }
                    },
                    progress: {
                        show: true,
                        width: 8
                    },
                    splitLine: {
                        distance: -4,
                        length: 4,
                        lineStyle: {
                        width: 1,
                        color: '#999'
                        }
                    },
                    axisLabel: {
                        distance: -30,
                        color: '#999',
                        fontSize: 10
                    },
                    anchor: {
                        show: false
                    },
                    title: {
                        show: true
                    },
                    detail: {
                        valueAnimation: true,
                        width: '60%',
                        lineHeight: 40,
                        borderRadius: 8,
                        offsetCenter: [0, '-15%'],
                        fontSize: 20,
                        fontWeight: 'bolder',
                        formatter: '{value} °C',
                        color: 'inherit'
                    },
                    // data: [
                    //     {
                    //     value: 50.23,
                    //     name:"电线温度"
                    //     }
                    // ]
                     data:this.infos.wireTemp
                }]
            }
       }
    },
    methods:{
    },
    mounted(){
        this.speedChart = echarts.init(this.$refs.speedCharts)
        this.tempChart = echarts.init(this.$refs.tempCharts)
        this.torsionChart = echarts.init(this.$refs.torsionCharts)
        this.wireChart = echarts.init(this.$refs.wireCharts)
        this.posChart = echarts.init(this.$refs.posCharts)

        this.speedChart.setOption(this.speedOption)
        this.tempChart.setOption(this.tempOptions)
        this.torsionChart.setOption(this.torsionOptions)
        this.wireChart.setOption(this.wireOptions)
        this.posChart.setOption(this.posOptions)
    },
    watch: { 
      infos: {
        handler (newValue, oldValue) {
            console.log("reerere32323")
        //   if (oldValue != newValue) {
            
             this.speedChart.setOption(this.speedOption)
             this.tempChart.setOption(this.tempOptions)
             this.torsionChart.setOption(this.torsionOptions)
             this.wireChart.setOption(this.wireOptions)
             this.posChart.setOption(this.posOptions)
        //   }
        },
        deep: true
      }
    }
})
</script>

<style scoped>
.parent{
    width: 100%;
    height: 100%;
    margin: 0 auto;
    /*启用弹性布局，主轴与交叉轴都采用居中对齐*/
    display: flex;
    justify-content: center;
    align-items: center;
}
.basic-data-item {
  background: #0f142b;
  border: none;
    background: #1c3beb;
}
.incremento-up-value {
  color: #60FE5E;
}
.incremento-down-value {
  color: #F44182;
}
.basic-data-item-title {
  color: #bcbcbe;
  font-size: 16px;
  padding-bottom: 5px;
}
.basic-data-item-value {
  color: #fff;
  font-size: 40px;
  font-weight: bolder;
}
.charts-chart {
margin-top:0;
height: 200px;
width:100%
}
</style>