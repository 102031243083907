import * as request from '@/utils/request'

/**
 * 接口封装
 */
export default {
  // getOverall() {
  //   let url = `/api/big/dsProjectSummary`
  //   return request.get(url)
  // },
  // getProvinceDataList() {
  //   let url = `/api/covid-19/area/latest/list?_=${Math.random()}`
  //   return request.get(url)
  // },
  // getDailyList() {
  //   let url = `/api/covid-19/list?t=${Math.random()}`
  //   return request.get(url)
  // }
  getTopBaseInfo(){
     let url = `/api/big/dsProjectSummary`
     return request.get(url)
  },
  getAlarmAllCount(){
    let url = `/api/big/getAlarmByYear`
    return request.get(url)
  },
  getAlarmAllMonthCount(){
    let url = `/api/big/getAlarm`
    return request.get(url)
  },
  //获取编码器一周内的报警情况
  getAlarmWeekData(){
    let url = `/api/big/getAlarmWeek`
    return request.get(url)
  },
  //获取项目列表
  getProjectList(params){
    let url = `/api/big/getProjectListByProvince`
    return request.post(url,params)
  },
  //获取终端列表
  getTerminalListByProjectId(params){
    let url = `/api/big/getTerminalList`
    return request.post(url,params)
  },
  //获取电机状态列表
  getMotorStatusListByTerminalId(params){
    let url = `/api/big/getMotorStatusList`
    return request.post(url,params)
  },
  //获取所有项目
  getAllProjects(){
    let url = `/api/big/getAllProject`
    return request.get(url)
  },
  //获取所有项目
  getMotorStatusList(params){
    let url = `/api/big/getMotorStatusList`
    return request.post(url,params)
  }
}
