<template>
    <div class="main-content" style="width:100%;height:860px;">
        <dv-border-box-9 style="width:100%;height:100%;">
            <div class="main-inner">
                <!-- <MotorStatus /> -->
            </div>
            <ul class="infinite-list" style="overflow:auto;height: 840px;margin-left: 15px;margin-top: 2px;;margin-right:10px;">
                <li v-for="(item ,idx) in motorStatusList" class="infinite-list-item" :key="idx">
                    <MotorStatus 
                    :name = item.name
                    :infos = item.ret
                    />
                </li>
            </ul>
        </dv-border-box-9>
    </div>
    
</template>
<script>
import MotorStatus from "../components/ds/MotorStatus"
import dsRequest from '../api/ds'

export default ({
    props: {
      terminal_id: String
    },
   components:{
         MotorStatus
   },
   data () {
      return {
        motorStatusList: 0
      }
    },
   methods: {
      getMotorStatus()
      {
        dsRequest.getMotorStatusList({terminal_id:this.terminal_id}).then((res) =>{
            if (res.code == 1) {
                    this.motorStatusList = res.data
                } 
            })
      }
    },
    mounted(){
        // let self = this
        this.getMotorStatus()
        //定义定时器，隔 5 秒刷新一次
        // let timer = setInterval(() => {
        //     self.getMotorStatus()
        // }, 5000)
        // this.$once('hook:beforeDestroy', () => {
        //     clearInterval(timer);
        //     timer = null;
        // })
    }
})
</script>